@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}
* {
  font-family: 'inter';
}
@font-face {
  font-family: 'Modes';
  src: url(../../public/fonts/MODES___.TTF);
}

@font-face {
  font-family: 'Supply';
  src: url(../../public/fonts/Supply-Regular.otf);
}

@font-face {
  font-family: 'Acid';
  src: url(../../public/fonts/AcidGrotesk-Regular.otf);
}

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background: #fafafa;
    width: 20px;
    border-radius: 16px;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 16px;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c9c9c9;
  }

  .scrollbarY::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 16px;
  }

  .scrollbarY::-webkit-scrollbar-track {
    background: #fafafa;
    width: 5px;
    border-radius: 16px;
  }

  .scrollbarY::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 16px;
  }

  .scrollbarY::-webkit-scrollbar-thumb:hover {
    background: #c9c9c9;
  }

  /* margin scrollbar */
  .scrollbar-margin::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  .scrollbar-margin::-webkit-scrollbar-track {
    background: #fafafa;
    margin: 20px 0;
    width: 20px;
  }

  .scrollbar-margin::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 16px;
  }

  .scrollbar-margin::-webkit-scrollbar-thumb:hover {
    background: #c9c9c9;
  }

  .invisible-scrollbar::-webkit-scrollbar {
    display: none;
  }
}

body {
  font-family: 'Modes';
  box-sizing: border-box;
}

.hover-image {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.5s ease;
}

.hover-container:hover .hover-image {
  opacity: 1;
}

.Toastify__toast-container {
  z-index: 100000 !important;
}

/* width */
/* ::-webkit-scrollbar {
  width: 0px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  border-radius: 0px;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  border-radius: 0px;
} */

.circleIcon {
  position: relative;
  width: 22px;
  height: 22px;
  border: none;
  border-radius: 50%;

  display: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circleIcon::after {
  position: absolute;
  top: -4px;
  content: '';
  left: -4px;
  width: 30px;
  height: 30px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: transparent;
  display: inherit;
}

.tab {
  position: relative;
  display: inline-block;
  padding: 10px 32px 4px 20px;
  text-decoration: none;
  margin: 0 -2px;
  margin-bottom: -1px;
  cursor: pointer;
}

.tab::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  border: 1px solid #cececc;
  border-bottom: none;
  border-radius: 10px 10px 0 0;
  background: #fbfafa;
  transform: perspective(5px) rotateX(1.5deg);
  transform-origin: bottom;
  transform-origin: bottom left;
}

textarea {
  padding: 5px;
}

/* table {
  border: 1px solid lightgray;
}

tbody {
  border-bottom: 1px solid lightgray;
}

th {
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  padding: 2px 4px;
} */

tfoot {
  color: gray;
}

tfoot th {
  font-weight: normal;
  padding: 5px;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-donut {
  border-radius: 100%;
  animation: skeleton-donut-loading 1s linear infinite alternate;
}

@keyframes skeleton-donut-loading {
  0% {
    border-color: hsl(60, 7%, 95%);
  }

  100% {
    border-color: hsl(60, 7%, 90%);
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(60, 7%, 95%);
  }

  100% {
    background-color: hsl(60, 7%, 90%);
  }
}

.skeleton-text {
  width: 100%;
  height: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.icon-svg:hover .icon-path {
  stroke: #2d2d2c;
}

.underline-tabs {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #2d2d2c;
  transform-origin: 0 0;
  transition: transform 0.25s;
}
div[data-lastpass-icon-root='true'] {
  opacity: 0;
}

div[data-lastpass-infield='true'] {
  opacity: 0;
}
/* WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 5px;
  /* Width of the scrollbar */
}

::-webkit-scrollbar:horizontal {
  height: 5px;
  /* Width of the scrollbar */
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Track color */
}

::-webkit-scrollbar-thumb {
  background: #888;
  /* Thumb color */
  border-radius: 5px;
  /* Rounded corners for the thumb */
}

/* Firefox */
/* Note: Firefox does not support customizing the scrollbar's width or rounded corners */
/* You can only change colors and appearance */

/* Track color in Firefox */
scrollbar {
  background-color: #f1f1f1;
}

/* Thumb color in Firefox */
scrollbar-thumb {
  background-color: #888;
}

.ag-overlay .ag-overlay-panel .ag-overlay-wrapper .ag-react-container {
  width: 100%;
  text-align: center;
}

.ag-theme-quartz .ag-header-cell {
  font-size: 12px;
  color: #6b7280;
}
.ag-checkbox-input:disabled {
  background-color: red;
  /* Set the background color to red when disabled */
  border: 2px solid purple;
  /* Set the border color to purple when disabled */
  -webkit-appearance: none;
  /* Remove default styles in WebKit browsers */
  appearance: none;
  /* Remove default styles */
  /* Dim the text color */
  cursor: not-allowed;
  /* Show a 'not-allowed' cursor on hover */
}
.ag-theme-quartz .ag-header {
  height: 42.5px !important;
}

.ag-theme-quartz .ag-header-cell {
  font-size: 12px;
  color: #6b7280;
}
.grid-table-row:hover {
  /* background-color: #fbfafa; */
  @apply bg-grey-100;
}
.flip-card {
  perspective: 1000px;
}
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
.flip-card.flip .flip-card-inner {
  transform: rotateY(180deg);
}
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.flip-card-back {
  transform: rotateY(180deg);
}

.card-bg {
  background: url(../../public/svg/card-background.svg);
}

.mantine-UnstyledButton-root svg {
  height: 20px !important; /* Adjust the percentage or use a fixed value like px */
  width: 20px !important; /* Adjust the percentage or use a fixed value like px */
}

.mantine-Notification-icon {
  width: 0px !important; /* Adjust the percentage or use a fixed value like px */
  height: 0px !important; /* Adjust the percentage or use a fixed value like px */
  background-color: transparent !important; /* Remove the background color on hover */
}

.mantine-Notification-root[data-variant='success']::before {
  content: none !important; /* Removes the content of the :before pseudo-element */
  background: none !important; /* Ensures background is removed */
  display: none !important; /* Hides the pseudo-element */
  width: 0px !important; /* Adjust the percentage or use a fixed value like px */
  height: 0px !important; /* Adjust the percentage or use a fixed value like px */
}

.mantine-Notification-root[data-variant='error']::before {
  content: none !important; /* Removes the content of the :before pseudo-element */
  background: none !important; /* Ensures background is removed */
  display: none !important; /* Hides the pseudo-element */
  width: 0px !important; /* Adjust the percentage or use a fixed value like px */
  height: 0px !important; /* Adjust the percentage or use a fixed value like px */
}
