.customMantineInput {
  &[data-error] {
    outline: rem(2px) solid var(--mantine-color-gray-2);
    border-color: var(--mantine-color-gray-2);
    color: var(--mantine-color-primary-7);
  }
}

.customMantineInputLabel {
  font-size: 12px;
  margin-bottom: 6px;
}

.customMantineInputError {
  @apply pl-[20px] relative;
  padding-top: 4px;
  font-size: 12px;
}

.customMantineInputError::before {
  content: '';
  margin-top: 2px;
  background-image: url(../../../public/svg/warning.svg);
  background-size: contain;
  transform: translateY(-50%);
  @apply w-[12px] h-[12px] absolute left-0 top-[50%] bg-no-repeat inline-block;
}

.group {
  margin-bottom: var(--mantine-spacing-xs);
}

.groupLabel {
  background-color: light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
}

.hiddenFilterIcon button {
  display: none;
}

.button:disabled {
  background: var(--button-bg, var(--mantine-primary-color-filled));
  border: var(--button-bd, calc(0.0625rem * var(--mantine-scale)) solid transparent);
  opacity: 0.4;
}
.button:disabled {
  background: var(--button-bg, var(--mantine-primary-color-filled));
  border: var(--button-bd, calc(0.0625rem * var(--mantine-scale)) solid transparent);
  opacity: 0.4;
}
.actionIcon:disabled {
  background: var(--ai-bg, var(--mantine-color-gray-1));
  border: var(--ai-bd, calc(0.0625rem * var(--mantine-scale)) solid transparent);
  opacity: 0.4;
}
