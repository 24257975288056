.wrapper {
  display: flex;
}

.chainBlockParent:not(:first-child) {
  margin-left: -10px;
}

.chainBlock {
  position: relative;
  top: 0;
  transition: top ease 1s;
}
