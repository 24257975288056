.label {
    color: #344054;
    margin-bottom: 8px;
  
    &[data-size='md'] {
      font-size: 14px;
    }
    &[data-size='lg'] {
      font-size: 14px;
    }
  }

  .input {
    color: #2D2D2C !important;
    font-size: 14px !important;
    box-sizing: border-box;
  
    border: 1px solid rgba(241, 241, 239, 1) !important;
    &::placeholder {
      color: #B5B5B3  !important;
    }
  
    &:focus {
      border: 1px solid #ddd;
      box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
      outline: none;
    }
    &[data-error='true'] {
      border: 1px solid #c61616 !important; /* Set border color to red to match the Figma design */
    box-shadow: none !important; /* Remove box-shadow to match the Figma design */
    }


  
  }


  .error {
    @apply pl-[20px] relative !important;
    padding-top: 4px !important;
    font-size: 12px !important;
    color: #c61616 !important; 
    
  }
  
  .error::before {
    content: '';
    margin-top: 1px;
    background-image: url(/svg/icons/error-circle-outlined-red.svg);
    background-size: contain;
    transform: translateY(-50%);
    @apply w-[14px] h-[14px] absolute left-0 top-[50%] bg-no-repeat inline-block;
  }

  .required {
    color: #c61616 !important;
  }


.option {
  color: #2D2D2C !important;
  font-size: 14px !important;
}
 
.dropdownEmpty {
  color: #B5B5B3 !important;
  font-size: 14px !important;
  
}