.dropdown {
  &[data-dates-dropdown] {
    padding: 8px 8px 6px 8px;
    z-index: 9999 !important;
  }
}

.day {
  font-size: 12px;
  width: 32px;
  height: 32px;

  &[data-in-range] {
    border-radius: 100%;
  }

  &[data-selected] {
    border-radius: 100%;
  }
}

.label {
  color: #344054;
  margin-bottom: 8px;
  font-weight: 600;

  &[data-size='md'] {
    font-size: 14px;
  }

  &[data-size='lg'] {
    font-size: 14px;
  }
}

.input {
  box-sizing: border-box;
  border: 1px solid rgba(241, 241, 239, 1);

  &[aria-expanded='true'] {
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
    outline: none;
  }
}

.calendarHeaderControl {
  border: 1px solid var(--mantine-color-gray-2);
  width: 32px;
  height: 32px;
}

.calendarHeaderLevel {
  font-size: 16px;
}

.weekday {
  font-size: 12px;
  padding-bottom: 4px;
}

.levelsGroup {
  gap: 8px;
}
