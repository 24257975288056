.tab {
  @mixin hover {
    background-color: transparent;
  }
  &[data-variant='outline'] {
    background: transparent;
    position: relative;
    display: inline-block;
    border: none;
    border-bottom: 1px solid;
    border-bottom-color: var(--tab-border-color);
    font-size: 12px;
    padding: calc(0.875rem * var(--mantine-scale)) calc(1.875rem * var(--mantine-scale))
      calc(0.75rem * var(--mantine-scale)) calc(1.5rem * var(--mantine-scale));

    text-decoration: none;
  }
  &[data-variant='outline'][data-active='true'] {
    border: none;
  }
  &[data-variant='default'] {
    padding: 10px 24px;
  }
}

.tab[data-variant='outline']::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  border: 1px solid;
  border-top-color: var(--tab-border-color);
  border-bottom-color: var(--tab-border-color);
  border-inline-start-color: var(--tab-border-color);
  border-inline-end-color: var(--tab-border-color);
  border-bottom: transparent;

  background: var(--tabs-color);
  border-radius: 10px 10px 0 0;
  transform: perspective(10px) rotateX(1.5deg);
  transform-origin: bottom;
  transform-origin: bottom left;
  width: auto;
  height: auto;
}

.outlineTab {
  background-color: '#FBFAFA';
}

.outlineTabList {
  margin-bottom: -1px;
}
