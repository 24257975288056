.tooltip {
  border: 1px solid #eaeaec;
  padding: 8px 16px;
  font-weight: 400;
  white-space: normal;
}

.arrow {
  border: 1px solid #eaeaec;
}
