.root {
  --modal-size-md: calc(38.75rem * var(--mantine-scale));
  --modal-size-lg: calc(48.75rem * var(--mantine-scale));
  --modal-size-xl: calc(58.75rem * var(--mantine-scale));
}

.content {
  &[data-variant='hq-default'] {
    border-radius: 14px;
  }
}

.header {
  &[data-variant='hq-default'] {
    padding: 24px;
    padding-bottom: 20px;
  }
}

.overlay {
  &[data-variant='hq-default'] {
    background-color: rgb(55 65 81 / var(--opacity-1));
  }
}

.close {
  border-radius: 100% !important;
  background-color: #f3f5f7;

  &:hover {
    @apply bg-gray-200;
  }
}

.title {
  &[data-variant='hq-default'] {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
  }
}
